<template>
  <div style="width: 97%">
    <div class="row">
      <div class="col-md-6">
        <label>{{ $t("emploi.listeEnseignants") }}</label>
        <a-select
          id="selectClass"
          class="mb-3"
          show-search
          :placeholder="$t('emploi.listeEnseignants')"
          option-filter-prop="children"
          :filter-option="filterOption"
          style="width: 400px; margin-left: 15px"
          @change="
            (val) => {
              selectTeacher(val);
            }
          "
        >
          <a-select-option
            v-for="teacher in teachers"
            :key="teacher._id"
            :value="teacher._id"
          >
            {{ teacher.employee.firstName + " " + teacher.employee.lastName }}
          </a-select-option>
        </a-select>
      </div>
      <div class="col-md-6">
        <a-button
          v-if="selectedTeacher"
          type="primary"
          style="float: right"
          :loading="loadingSave"
          @click="saveAvailability"
          icon="save"
        >
          {{ $t("all.save") }}
        </a-button>
      </div>
    </div>
    <a-divider></a-divider>

    <div class="mb-2 mt-0"></div>

    <a-col class="mt-5" v-if="!loaded" :span="16" :offset="11">
      <a-spin size="large" />
    </a-col>
    <div v-else>
      <div v-if="selectedTeacher">
        <div class="mt-2 mb-2 d-flex">
          <div class="legend-available"></div>
          <div class="ml-2 mr-2">{{ $t("teacherAvailability.available") }}</div>
        </div>

        <div class="mt-2 mb-4 d-flex">
          <div class="legend-unavailable"></div>
          <div class="ml-2 mr-2">
            {{ $t("teacherAvailability.notAvailable") }}
          </div>
        </div>
        <div
          v-if="noAvailability"
          class="d-flex justify-content-center mt-2 mb-2"
        >
          <a-tag
            style="font-size: 17px; padding: 5px"
            color="orange"
            size="large"
          >
            {{ $t("teacherAvailability.notFilledYet") }}</a-tag
          >
        </div>
        <div class="grid-header">
          <div class="grid-cell header-cell">{{ $t("emploi.temps") }}</div>
          <div v-for="day in days" :key="day" class="grid-cell header-cell">
            {{ $t("days." + day) }}
          </div>
        </div>
        <div
          v-for="(time, rowIndex) in timeSlots"
          :key="rowIndex"
          class="grid-row"
        >
          <div class="time-slot">{{ time }}</div>
          <div
            v-for="(day, colIndex) in days"
            :key="colIndex"
            class="grid-cell"
            :class="{
              'available-cell': availability[day][time],
              'unavailable-cell': !availability[day][time],
            }"
            @click="toggleAvailability(day, time)"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import { register } from "vue-advanced-chat";
import apiClient from "@/services/axios";
import { mapState } from "vuex";

import moment from "moment";

register();
export default {
  computed: {
    ...mapState(["settings", "user"]),
  },
  components: {},
  data() {
    return {
      loaded: true,
      teachers: [],
      timeSlots: [
        "08:00 - 09:00",
        "09:00 - 10:00",
        "10:00 - 11:00",
        "11:00 - 12:00",
        "12:00 - 13:00",
        "13:00 - 14:00",
        "14:00 - 15:00",
        "15:00 - 16:00",
        "16:00 - 17:00",
        "17:00 - 18:00",
      ],
      days: [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
      ],
      availability: {
        monday: {},
        tuesday: {},
        wednesday: {},
        thursday: {},
        friday: {},
        saturday: {},
      },
      selectedTeacher: undefined,
      noAvailability: false,
      availabilityId: undefined,
      loadingSave: false,
    };
  },

  async created() {
    this.days.forEach((day) => {
      this.availability[day] = {};
      this.timeSlots.forEach((time) => {
        this.availability[day][time] = true;
      });
    });

    await apiClient
      .post("/teachers/filter", {
        query: { status: "active" },
      })
      .then((res) => (this.teachers = res.data));
  },

  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },

    async selectTeacher(val) {
      this.loaded = false;
      this.selectedTeacher = val;
      this.noAvailability = false;
      this.availabilityId = undefined;

      await apiClient
        .post("/teacherAvailability/filter", {
          query: { teacher: val },
        })
        .then(({ data }) => {
          if (data.length) {
            data = data[0];
            this.availabilityId = data._id;

            this.days.forEach((day) => {
              this.availability[day] = {};
              data[day].map((time) => {
                this.availability[day][time.start + " - " + time.end] = true;
              });
            });
          } else {
            this.days.forEach((day) => {
              this.availability[day] = {};
              this.timeSlots.forEach((time) => {
                this.availability[day][time] = true;
              });
            });
            this.noAvailability = true;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loaded = true;
        });

      this.loaded = true;
    },
    saveAvailability() {
      this.loadingSave = true;
      const data = {};
      this.days.forEach((day) => {
        data[day] = Object.keys(this.availability[day])
          .filter((time) => this.availability[day][time])
          .map((time) => ({
            start: time.split(" - ")[0],
            end: time.split(" - ")[1],
          }));
      });

      if (this.availabilityId)
        apiClient
          .patch("/teacherAvailability/" + this.availabilityId, {
            data,
          })
          .then(() => {
            this.$message.success(this.$t("teacherAvailability.saved"));
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            this.loadingSave = false;
          });
      else
        apiClient
          .put("/teacherAvailability", {
            ...data,
            teacher: this.selectedTeacher,
          })
          .then(() => {
            this.$message.success(this.$t("teacherAvailability.saved"));
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            this.loadingSave = false;
          });
    },
    toggleAvailability(day, time) {
      this.availability[day][time] = !this.availability[day][time];
      this.availability = { ...this.availability };
    },
  },
};
</script>

<style lang="scss" scoped>
.legend-available {
  background-color: #47be7d;
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 15px;
}

.legend-unavailable {
  background-color: #e4e6ef;
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 15px;
}
.availability-grid {
  display: grid;
  grid-template-columns: 1fr repeat(6, minmax(100px, 1fr)); /* Adjust the column widths as needed */
  grid-gap: 1px;
  align-items: center;
}

.grid-header {
  display: grid;
  grid-template-columns: 1fr repeat(6, minmax(100px, 1fr)); /* Adjust the column widths as needed */
  background-color: #1c4c74;
  font-weight: bold;
  text-align: center;
  color: white;
}

.grid-row {
  display: grid;
  grid-template-columns: 1fr repeat(6, minmax(100px, 1fr)); /* Adjust the column widths as needed */
  align-items: center;
  color: white;
}

.time-slot {
  border: 1px solid #ccc;
  background-color: #1c4c74;
  font-weight: bold;
  text-align: center;
  padding: 10px;
}

.grid-cell {
  border: 1px solid #ccc;
  width: 100%; /* Adjust the cell width as needed */
  height: 100%; /* Adjust the cell height as needed */
  cursor: pointer;
}

.available-cell {
  background-color: #47be7d;
}

.unavailable-cell {
  background-color: #e4e6ef;
}
</style>
